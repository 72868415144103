import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import HomepageHero from '../components/Homepage/HomepageHero';
import HomepageLookingFor from '../components/Homepage/HomepageLookingFor';
import HomepageServices from '../components/Homepage/HomepageServices';
import HomepageOverview from '../components/Homepage/HomepageOverview';
import HomepageTesimonials from '../components/Homepage/HomepageTesimonials';

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <Helmet>
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://www.alternativedev.com",
          "name": "ALTdev",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+1-508-367-1837",
            "contactType": "Customer Support"
          }
        }
      `}
        </script>
      </Helmet>
      <HomepageHero />
      <HomepageLookingFor />
      <HomepageOverview />
      <HomepageServices />
      <HomepageTesimonials />
    </Layout>
  );
};

export default IndexPage;
