
		import React from 'react';

		function BrowserIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 512, 512">
					<path d="M464 32h-416C21.49 32 0 53.49 0 80v352C0 458.5 21.49 480 48 480h416c26.51 0 48-21.49 48-48v-352C512 53.49 490.5 32 464 32zM16 80c0-17.64 14.36-32 32-32h40V128H16V80zM496 432c0 17.64-14.36 32-32 32h-416c-17.64 0-32-14.36-32-32v-288h480V432zM496 128H104V48H464c17.64 0 32 14.36 32 32V128z" />
				</svg>
			);
		}

		export default BrowserIcon;
	