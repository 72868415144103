
		import React from 'react';

		function CheckIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 512, 512">
					<path d="M192 392c-2.047 0-4.094-.7813-5.656-2.344l-152-152c-3.125-3.125-3.125-8.188 0-11.31s8.188-3.125 11.31 0L192 372.7l274.3-274.3c3.125-3.125 8.188-3.125 11.31 0s3.125 8.188 0 11.31l-280 280C196.1 391.2 194 392 192 392z" />
				</svg>
			);
		}

		export default CheckIcon;
	