import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Section from '../Section';
import Heading from '../Styles/Heading';
import Text from '../Styles/Text';
import Grid from '../Styles/Grid';
import Box from '../Styles/Box';
import CardBasic from '../CardBasic';
import ListCheckIcon from '../Icons/Fa/Thin/ListCheckIcon';
import BrowserIcon from '../Icons/Fa/Thin/BrowserIcon';
import LaptopCodeIcon from '../Icons/Fa/Thin/LaptopCodeIcon';

function HomepageLookingFor() {
  return (
    <Looking__Section pt={0} topDivider={true}>
      <BoxStyles>
        <Heading className="fancyHeadline">
          I am looking for help with...
        </Heading>
        <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gridGap={4}>
          <CardBasicStyles
            icon={<BrowserIcon />}
            heading="New Website"
            link="/services/web-design/"
            linkAltText="Help with creating a new website"
          />
          <CardBasicStyles
            icon={<LaptopCodeIcon />}
            heading="An existing website"
            link="/services/web-design/"
            linkAltText="Help with an existing website"
          />
          <CardBasicStyles
            icon={<ListCheckIcon />}
            heading="Managing a website"
            link="/services/support-maintenance/"
            linkAltText="Help with managing a website"
          />
        </Grid>
      </BoxStyles>
    </Looking__Section>
  );
}

const Looking__Section = styled(Section)`
  background: var(--darkPurple);
  position: relative;
  color: var(--light);
  .gatsby-image-wrapper {
    margin-top: 2em;
    left: -25%;
  }
  .inner {
    z-index: 10;
    position: relative;
  }
`;

const BoxStyles = styled(Box)`
  @media (min-width: 960px) {
    margin-right: 40%;
  }
`;

const CardBasicStyles = styled(CardBasic)`
  text-align: center;
  padding: 2em;
  border-radius: 0.9em;
  h3 {
    margin: 0;
  }
  &:hover {
    box-shadow: 0 0 0 5px var(--accent);
  }
`;

export default HomepageLookingFor;
