import React from 'react';
import styled from 'styled-components';
import PageHeader from '../PageHeader';
import Section from '../Section';
import Grid from '../Styles/Grid';
import Heading from '../Styles/Heading';
import Text from '../Styles/Text';
import codeLines from '../../images/code-lines-compact.svg';
import ButtonText from '../Buttons/ButtonText';
import { Link } from 'gatsby';

function HomepageHero() {
  return (
    <StyledSection>
      <Heading as="h1" className="preHeading">
        Website Design Boston
      </Heading>
      <Text as="span" className="h1">
        Let your website do the talking.
      </Text>
      <Text>
        ALTdev is a group of passionate and creative professionals. We focus on
        supporting individuals and organizations, using our experience, skill,
        and passion for design, development, and all things web.
      </Text>
      <Link to="/contact/">
        <ButtonText>Need Website Help? Contact Us</ButtonText>
      </Link>
    </StyledSection>
  );
}

const StyledSection = styled(Section)`
  min-height: 55vh;
  h1 {
    margin-bottom: 0;
  }
  :after {
    content: '';
    display: none;
    position: absolute;
    z-index: -1;
    width: 759px;
    height: 741px;
    background: url(${codeLines}) no-repeat;
  }
  @media (min-width: 960px) {
    min-height: 55vh;
    & > div > * {
      margin-right: 50%;
    }
    :after {
      display: block;
      left: 50%;
      opacity: 0.75;
      top: 10%;
      z-index: 1;
    }
  }
`;

export default HomepageHero;
