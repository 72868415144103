import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Section from '../Section';
import Heading from '../Styles/Heading';
import Text from '../Styles/Text';
import Typography from '../Styles/Typography';
import Grid from '../Styles/Grid';
import Card from '../Styles/Card';
import Avatar from '../Avatar';
import ButtonText from '../Buttons/ButtonText';
import codeLinesWhite from '../../images/code-lines-white.svg';

function HomepageTesimonials({ image }) {
  const data = useStaticQuery(graphql`
    query {
      ryanE: file(relativePath: { eq: "ryan-e-google-avatar.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      scottD: file(relativePath: { eq: "scott-d-google-avatar.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      alyssaAnn: file(relativePath: { eq: "alyssa-ann-google-avatar.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <SectionStyles bottomDivider={true} dividerBottomFill="var(--lightBlue)">
      <Heading className="fancyHeadline">
        What People Are Saying About Us
      </Heading>
      <Grid
        gridTemplateColumns={['1fr', '1fr', '1fr 1fr 1fr']}
        gridGap={4}
        alignItems="self-start"
      >
        <CardStyles>
          <Typography className="h3">
            I have been working with Alternative Dev for over 2 years on
            multiple projects and they have been fantastic. Patrick is
            professional and easy to work with. He and his team are responsive
            and willing to go the extra mile to make the website project
            perfect. Highly recommend!
          </Typography>
          <Typography>
            <b>— Ryan E.</b>
          </Typography>
          <Avatar image={data.ryanE.childImageSharp.gatsbyImageData} />
        </CardStyles>
        <CardStyles>
          <Typography className="h3">
            Patrick does great work. He is fast, dependable and always brings
            new solutions to the table. Highly recommend!
          </Typography>
          <Typography>
            <b>— Scott D.</b>
          </Typography>
          <Avatar image={data.scottD.childImageSharp.gatsbyImageData} />
        </CardStyles>
        <CardStyles>
          <Typography className="h3">
            Patrick has been amazing through the entire process of putting
            together my brand new webstore. He has a ton of knowledge and has
            made every little request I had happen in record time! Thank you for
            building the perfect page and continuing to work on it with me as
            any questions pop up!
          </Typography>
          <Typography>
            <b>— Alyssa Ann</b>
          </Typography>
          <Avatar image={data.alyssaAnn.childImageSharp.gatsbyImageData} />
        </CardStyles>
      </Grid>
      <a
        href="https://g.page/r/CV8OTj-IkMvVEAg/review "
        target="_blank"
        rel="noreferrer"
      >
        <ButtonText>Leave us a review</ButtonText>
      </a>
    </SectionStyles>
  );
}

const SectionStyles = styled(Section)`
  --primary: var(--accent);
  color: var(--light);
  background: var(--darkPurple);
  button {
    // Fixes contrast issue
    font-size: 120%;
    margin-top: 2em;
  }
  @media (max-width: 960px) {
  }
`;

const CardStyles = styled(Card)`
  position: relative;
  padding-bottom: 5.5em;
  .h3 {
    font-size: 1.3em;
    font-weight: normal;
  }
  & > span {
    position: absolute;
    right: 1.5em;
    bottom: 1.5em;
  }
`;

export default HomepageTesimonials;
