import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Section from '../Section';
import Grid from '../Styles/Grid';
import Flex from '../Styles/Flex';
import Box from '../Styles/Box';
import Typography from '../Styles/Typography';
import CardBasic from '../CardBasic';
import List from '../List';
import ListItem from '../ListItem';
import CheckIcon from '../Icons/Fa/Thin/CheckIcon';
import BigNumber from '../BigNumber';

function HomepageServices() {
  return (
    <SectionStyles>
      <Info>
        <Typography as="h2" className="fancyHeadline">
          Our Services...
        </Typography>
        <Typography>
          ALTdev is one of the top web development companies that uses our
          knowledge, skills, and technological expertise to deliver world-class
          development services. We can deliver strategically designed and
          creatively crafted websites and web apps to take your business to the
          next level.
        </Typography>
      </Info>
      <Grid
        gridTemplateColumns={['1fr', '1fr 1fr', 'repeat(3, 1fr)']}
        gridGap={4}
      >
        <Box>
          <BigNumber text="01" />
          <Typography as="h3">Design</Typography>
          <List>
            <ListItem
              icon={<CheckIcon />}
              text="Custom Web Design"
              link="/services/web-design"
            />
            <ListItem icon={<CheckIcon />} text="Responsive Design" />
            <ListItem icon={<CheckIcon />} text="Single Page Websites" />
            <ListItem icon={<CheckIcon />} text="Landing Pages" />
            <ListItem icon={<CheckIcon />} text="Prototyping" />
          </List>
        </Box>
        <Box>
          <BigNumber text="02" />
          <Typography as="h3">Development</Typography>
          <List>
            <ListItem icon={<CheckIcon />} text="Custom Web Development" />
            <ListItem icon={<CheckIcon />} text="Web Apps" />
            <ListItem
              icon={<CheckIcon />}
              text="PSD to HTML (HTML5, CSS3, JS)"
            />
            <ListItem icon={<CheckIcon />} text="Static HTML to WordPress" />
          </List>
        </Box>
        <Box>
          <BigNumber text="03" />
          <Typography as="h3">Support &amp; Maintenance</Typography>
          <List>
            <ListItem
              icon={<CheckIcon />}
              text="Managed Web Hosting"
              link="/services/managed-web-hosting/"
            />
            <ListItem icon={<CheckIcon />} text="Content Updates" />
            <ListItem icon={<CheckIcon />} text="Plugin Updates" />
            <ListItem icon={<CheckIcon />} text="Security Audit" />
            <ListItem icon={<CheckIcon />} text="Performance Audit" />
          </List>
        </Box>
      </Grid>
    </SectionStyles>
  );
}

const SectionStyles = styled(Section)`
  background: var(--lightBlue);
  padding-top: 0;
`;

const Info = styled(Box)`
  position: relative;
  background: var(--light);
  padding: 3em 20% 3em 0;
  top: -2em;
  border-radius: 0;
  ::before {
    content: '';
    height: 100%;
    width: 50%;
    background: var(--light);
    position: absolute;
    left: -50%;
    top: 0;
  }
  @media (min-width: 960px) {
    margin-right: 25%;
    border-radius: 0 1000px 1000px 0;
  }
`;

export default HomepageServices;
