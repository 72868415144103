import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '../components/ListItem';
import List from '../components/List';

/**
 * @param {string} icon - Icons can be found at https://fontawesome.com/icons. This will be the same for all list items.
 * @param {Object} list - Array[] of objects with keys text and link (optional).
 * @example
 * <IconList
 *   icon={<CheckIcon />}
 *   list={[
 *     { text: 'List item one', link: '#' },
 *     { text: 'List item one' },
 *   ]}
 * />
 */

function IconList({ icon, list }) {
  return (
    <List>
      {list &&
        list.map((item) => (
          <ListItem icon={icon} text={item.text} link={item.link} />
        ))}
    </List>
  );
}

IconList.propTypes = {
  icon: PropTypes.element,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string,
    })
  ).isRequired,
};

export default IconList;
