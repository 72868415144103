import styled from 'styled-components';

import ButtonBase from './ButtonBase';

const ButtonText = styled(ButtonBase)`
  color: var(--primary);
  background: transparent;
  padding: 0.5em;

  border-radius: 0;
`;
export default ButtonText;
