import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Section from '../Section';
import Heading from '../Styles/Heading';
import Text from '../Styles/Text';
import Grid from '../Styles/Grid';
import Box from '../Styles/Box';
import codeLines from '../../images/code-lines-compact.svg';
import IconList from '../IconList';
import CheckIcon from '../Icons/Fa/Thin/CheckIcon';

function HomepageOverview() {
  const data = useStaticQuery(graphql`
    query {
      macbook: file(relativePath: { eq: "hdfhg_52_pkdsfpok1.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <Overview__Section>
      <Grid
        gridTemplateColumns={['1fr', '1fr 1fr']}
        gridGap={4}
        alignItems="center"
      >
        <Box className="content-wrapper">
          <Text as="span" className="preHeading">
            It’s 2021, stop living in the past.
          </Text>
          <Heading>Wave goodbye to outdated websites.</Heading>
          <Text>
            Our unique process gives you a website that meets the latest
            development, performance &amp; accessibility standards.
          </Text>
          <IconList
            icon={<CheckIcon />}
            list={[
              {
                text:
                  'Meet Web Content Accessibility Guidelines (WCAG) 2.1 standards out of the box.',
              },
              {
                text:
                  "Exceed Google's high and ever changing Core Web Vitals standards",
              },
              {
                text:
                  'All websites created using the latest web development standards',
              },
            ]}
          />
        </Box>
        <Box>
          <GatsbyImage
            image={data.macbook.childImageSharp.gatsbyImageData}
            altText="Sketch design software being used to desing a website layout"
          />
        </Box>
      </Grid>
    </Overview__Section>
  );
}

const Overview__Section = styled(Section)`
  --primary: var(--accent);
  color: var(--light);
  background: var(--darkPurple);
  padding-bottom: 4em;
  .inner {
    z-index: 10;
    position: relative;
  }
  :after {
    content: '';
    display: block;
    position: absolute;
    left: -20%;
    opacity: 0.09;
    top: 0;
    z-index: 1;
    width: 759px;
    height: 741px;
    background: url(${codeLines}) no-repeat;
  }
  @media (min-width: 960px) {
    :after {
      left: -10%;
      top: -15em;
    }
  }
`;

export default HomepageOverview;
