import React from 'react';
import styled from 'styled-components';
import Typography from './Styles/Typography';

function BigNumber({ text, color }) {
  return (
    <Styles as="span" aria-hidden="true" color={color}>
      {text}
    </Styles>
  );
}

const Styles = styled(Typography)`
  font-size: 5em;
  opacity: 0.05;
  color: ${({ color }) => (color ? color : 'var(--blue)')};
`;

export default BigNumber;
